import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import translationEL from '../locales/el/translations.json';
import translationEN from '../locales/en/translations.json';

const fallbackLng = ["el"];
// the translations
const resources = {
    en: {
        translation: translationEN
    },
    el: {
        translation: translationEL
    },
};


i18n
    // .use(Backend) // used to load data from other directory
    .use(LanguageDetector) // detects the current language
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        load: 'languageOnly',
        fallbackLng, // default language
        // detection: {
        //     checkWhitelist: true,
        // },
        // backend: {
        //     loadPath: `${apiUrl}/api/locales/{{lng}}/{{ns}}.json`,
        // },
        debug: true,
        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },
    });

export default i18n;